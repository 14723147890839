import {Button, Container} from "react-bootstrap";
import {Col, Row} from "react-bootstrap";
import DnsTestButton from "../components/DnsTestButton";
import {useEffect, useState} from "react";
import {detectBrowser, getBrowser} from "../utils/browserDetection";

export default function DnsConfigurationPage(props) {
    const data = props.data;
    const translations = data.translations;
    const browserConfig = data.browserConfig;
    const [dnsConfigurationTestFailed, setDnsConfigurationTestFailed] = useState(false);
    const [browser, setBrowser] = useState(detectBrowser(window.navigator.userAgent) || getBrowser(browserConfig.fallback));
    const [browserInstructions, setBrowserInstructions] = useState(translations.dnsConfiguration.instructions[browser.key]);

    useEffect(() => {
        setBrowserInstructions(translations.dnsConfiguration.instructions[browser.key])
    }, [browser, translations]);

    return (
        <Container fluid>
            <Row>
                <Col className="m-3 p-0 d-flex align-items-center">
                    <Container className="m-0 p-0">
                        <h1 className="display-6 fw-bold">{translations.dnsConfiguration.header1}</h1>
                        <h5 className="mt-2">{translations.dnsConfiguration.header2} {browser.label}</h5>
                        <h6 className="mt-2">{translations.dnsConfiguration.header3}</h6>
                        <Container className="m-0 p-0">
                            {browserConfig.enabledBrowsers.map((enabledBrowser, i) => {
                                const browserItem = getBrowser(enabledBrowser);
                                return (
                                    <Button
                                        key={`browser-selection-button-${i}`}
                                        className={`btn btn-sm mx-1 shadow-sm btn-${browser.key === browserItem.key ? "primary" : "secondary"}`}
                                        onClick={() => setBrowser(browserItem)}
                                    >{browserItem.label}</Button>
                                )
                            })}
                        </Container>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="m-3 p-0 d-flex align-items-center">
                    <Container>
                        <h5>{translations.dnsConfiguration.header4}</h5>
                        <Container className="fs-6">
                            <ol>
                                {browserInstructions.map((instructionComponent, i) => {
                                    return (
                                        <li key={`instruction-${i}`}>
                                            { instructionComponent }
                                        </li>
                                    )
                                })}
                            </ol>
                        </Container>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Container fluid>
                    <hr className="hr hr-blurry"/>
                </Container>
            </Row>
            <Row>
                <Col className="m-3 mt-0 p-0 d-flex align-content-center">
                    <DnsTestButton
                        label={translations.dnsConfiguration.testButtonLabel}
                        dnsTestUrl={data.dnsTestUrl}
                        successCallback={() => props.setIsEnrolmentComplete(true)}
                        failureCallback={() => setDnsConfigurationTestFailed(true)}

                    />
                </Col>
            </Row>
            {dnsConfigurationTestFailed && (
                <Row>
                    <Col className="m-3 p-0 d-flex align-items-center">
                        <Container fluid className="d-grid mx-auto">
                            <div className="alert alert-warning mx-auto">
                                <p className="fs-6" dangerouslySetInnerHTML={{__html: translations.dnsConfiguration.dnsTestFailureText}}/>
                            </div>
                        </Container>
                    </Col>
                </Row>
            )}
        </Container>
    )
}
