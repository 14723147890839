export const EXPOSED_PARAMETERS = [
    "companyName",
    "productName",
    "eulaUrl",
    "dnsResolverUrl",
    "profileDownloadUrl",
    "enrolmentCode"
]

export function getParametrisedTranslationConfig(data) {
        // Serialise the entire translations section.
        let translations = JSON.stringify(data.translationConfig.translations);

        // And replace placeholders by simple string replacement.
        EXPOSED_PARAMETERS.forEach((param) => {
                translations = translations.replaceAll(`$${param}$`, data[param]);
        })

        // Replace original translations.
        data.translationConfig.translations = JSON.parse(translations);
}
