import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Container, Card} from "react-bootstrap";
import {EnrolmentAPI} from "../api/EnrolmentAPI";
import TermsAndConditionsPage from "./TermsAndConditions";
import DnsConfigurationPage from "./DnsConfigurationPage";
import DonePage from "./DonePage";
import LanguageSelector from "../components/LanguageSelector";
import RoundSpinner from "../components/Spinner";
import ErrorMessage from "../components/ErrorMessage";
import {getParametrisedTranslationConfig} from "../utils/translation";
import {ERROR_HEADER, ERROR_GENERIC, ERROR_CODE_NOT_FOUND} from "../utils/errorsMessages";
import {getAbsoluteUrl} from "../utils/url";
import {injectInstructionTags} from "../utils/instructions";


export default function EnrolmentPage() {
    const params = useParams()

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEnrolmentComplete, setIsEnrolmentComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasAcceptedEula, setHasAcceptedEula] = useState(false);
    const [data, setData] = useState({
        enrolmentCode: null,
        companyName: null,
        productName: null,
        logoPath: null,
        eulaUrl: null,
        dnsResolverUrl: null,
        dnsTestUrl: null,
        browserConfig: null,
        translationConfig: null,
        translations: null,
        profileDownloadUrl: null,
    });

    useEffect(() => {
        if (isEnrolmentComplete) {
            EnrolmentAPI.disableEnrolmentCode(data.enrolmentCode).catch(
                () => {
                    setErrorMessage(ERROR_GENERIC);
                }
            )
        }
    }, [isEnrolmentComplete, data.enrolmentCode])

    const fetchData = () => {
        if (!isFetchingData) {
            setIsFetchingData(true);
            const enrolmentCode = params.enrolmentCode;

            EnrolmentAPI.getEnrolmentData(enrolmentCode).then(
                (enrolmentData) => {
                    enrolmentData.enrolmentCode = enrolmentCode;
                    enrolmentData.eulaUrl = atob(enrolmentData.eulaUrl.replace(/_/g,'/').replace(/-/g,'+'));
                    enrolmentData.profileDownloadUrl = EnrolmentAPI.getIOSProfileDownloadUrl(enrolmentCode);

                    getParametrisedTranslationConfig(enrolmentData);

                    setData({
                        enrolmentCode: enrolmentCode,
                        companyName: enrolmentData.companyName,
                        productName: enrolmentData.productName,
                        logoPath: getAbsoluteUrl(enrolmentData.logoPath),
                        eulaUrl: enrolmentData.eulaUrl,
                        dnsResolverUrl: enrolmentData.dnsResolverUrl,
                        dnsTestUrl: enrolmentData.dnsTestUrl,
                        browserConfig: enrolmentData.browserConfig,
                        translationConfig: enrolmentData.translationConfig,
                        translations: getTranslations(enrolmentData.translationConfig.translations[enrolmentData.translationConfig.defaultLanguage], enrolmentData),
                        profileDownloadUrl: enrolmentData.profileDownloadUrl,
                    });
                }
            ).catch(
                (error) => {
                    if (error?.response?.status === 404) {
                        setErrorMessage(ERROR_CODE_NOT_FOUND);
                    } else {
                        console.error(error);
                        setErrorMessage(ERROR_GENERIC);
                    }
                    setIsLoading(false);
                }
            ).finally(
                () => {
                    setIsLoading(false);
                }
            );
        }
    }

    const getTranslations = (translations, contextData) => {
        const newTranslations = JSON.parse(JSON.stringify(translations));
        injectInstructionTags(newTranslations, contextData);
        return newTranslations;
    }

    const setLanguage = (language) => {
        const newTranslations = getTranslations(data.translationConfig.translations[language], data)
        setData({...data, translations: newTranslations});
    }

    fetchData();

    return (
        <Container fluid className={"height-100vh"}>

            {isLoading && (
                <Container className="mt-5">
                    <RoundSpinner/>
                </Container>
            )}

            {!isLoading && errorMessage && (
                <ErrorMessage
                    header={ERROR_HEADER}
                    message={errorMessage}
                />
            )}

            {!isLoading && !errorMessage && (
                <Container fluid>
                    {data.translationConfig['enabledLanguages'].length > 1 && (
                        <Container className="enrolment-card mx-auto my-1 d-flex justify-content-end">
                            <LanguageSelector
                                default={data.translationConfig['enabledLanguages'][0]}
                                languages={data.translationConfig['enabledLanguages']}
                                onChangeCallback={(value) => { setLanguage(value); }}
                            />
                        </Container>
                    )}
                    <Container className="mx-auto min-height-50vh mt-1">
                        {!isEnrolmentComplete && (
                            <Card className="enrolment-card">
                                {!hasAcceptedEula && (
                                    <TermsAndConditionsPage
                                        data={data}
                                        setHasAcceptedEula={setHasAcceptedEula}

                                    />
                                )}

                                {hasAcceptedEula && (
                                    <DnsConfigurationPage
                                        data={data}
                                        setIsEnrolmentComplete={setIsEnrolmentComplete}
                                    />
                                )}
                            </Card>
                        )}

                        {isEnrolmentComplete && (
                            <DonePage
                                header={data.translations.done.header}
                                message={data.translations.done.message}
                            />
                        )}
                    </Container>
                </Container>
            )}
        </Container>
    )
}
