import HtmlString from "../components/custom-tags/HtmlString";
import ProfileDownloadLink from "../components/custom-tags/ProfileDownloadLink";
import ClipboardCopy from "../components/custom-tags/ClipboardCopy";
import {Fragment} from "react";


const TAG_REGEX = /<(?<opening_tag_name>.*?)>(?<content>.*?)<\/(?<closing_tag_name>.*?)>/i
const CLIPBOARD_COPY_TAG = 'ClipboardCopy';
const PROFILE_DOWNLOAD_TAG = 'ProfileDownloadLink';
const SUPPORTED_CUSTOM_TAGS = [
    CLIPBOARD_COPY_TAG,
    PROFILE_DOWNLOAD_TAG
]

const buildTagSplitRegex = (tags) => {
    const regexString = tags.reduce(
        (result, tag, index) => {
            const newTag = `<${tag}>.*?</${tag}>`;
            const separator = index === 0 ? '' : '|';
            return result + separator + newTag;
        },
        '',
    )
    return new RegExp(`(${regexString})`, 'i');
}

const CUSTOM_TAGS_SPLIT_REGEX = buildTagSplitRegex(SUPPORTED_CUSTOM_TAGS);

const getTag = (text, translations, applicationData) => {
    const m = text.match(TAG_REGEX);

    // Not a tag.
    if (m === null) {
        return (<HtmlString value={text}/>)
    }

    // Malformed opening+closing tag pair.
    if (m.groups.opening_tag_name !== m.groups.closing_tag_name) {
        return (<HtmlString value={`Malformed tag: ${text}`}/>)
    }

    const tag_name = m.groups.opening_tag_name.toLowerCase();

    if (tag_name === PROFILE_DOWNLOAD_TAG.toLowerCase()) {
        return (
            <ProfileDownloadLink
                value={m.groups.content}
                enrolmentCode={applicationData.enrolmentCode}
            />
        )
    }

    if (tag_name === CLIPBOARD_COPY_TAG.toLowerCase()) {
        return (
            <ClipboardCopy
                value={m.groups.content}
                copyTooltip={translations?.generics.copyToClipboardTooltip}
                copiedTooltip={translations?.generics.copiedToClipboardTooltip}
            />
        );
    }

    // Default: output as-is. For built-in tags.
    return (<HtmlString value={text}/>)
}


function InstructionTagsContainer(props) {
    return (
        props.tags.map((tag, index) => {
            return <Fragment key={index}>{tag}</Fragment>
        })
    )
}

function convertToTags(instruction, translations, applicationData) {
    const elements = instruction.split(CUSTOM_TAGS_SPLIT_REGEX);
    const tags = elements.map(
        (element) => {
            return getTag(element, translations, applicationData);
        }
    )
    return (
        <InstructionTagsContainer tags={tags}/>
    )
}


export function injectInstructionTags(translations, data) {
    data.browserConfig.enabledBrowsers.forEach((browser) => {
        translations.dnsConfiguration.instructions[browser].forEach((instruction, i) => {
            translations.dnsConfiguration.instructions[browser][i] = convertToTags(instruction, translations, data);
        });
    });
}
