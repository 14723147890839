import Bowser from "bowser";

export const BROWSER_CHROME = 'chrome';
export const BROWSER_EDGE = 'microsoft edge';
export const BROWSER_FIREFOX = 'firefox';
export const BROWSER_ANDROID = 'android';
export const BROWSER_IOS = 'ios';
export const BROWSER_MACOS = 'macos';

export const SUPPORTED_BROWSERS = [
    BROWSER_CHROME,
    BROWSER_EDGE,
    BROWSER_FIREFOX,
    BROWSER_ANDROID,
    BROWSER_IOS,
    BROWSER_MACOS,
]

export const BROWSER_LABELS = {
    chrome: 'Chrome',
    "microsoft edge": 'Edge',
    firefox: 'Firefox',
    android: 'android',
    ios: 'iOS',
    macos: 'macOS'
}

export function detectBrowser(userAgent) {
    const systemInfo = Bowser.parse(userAgent);
    const osName = systemInfo.os.name.toLowerCase();
    const browserName = systemInfo.browser.name.toLowerCase();

    let detectedBrowser = null;

    // OS-dependent
    if (osName === 'android') { detectedBrowser = BROWSER_ANDROID; }
    if (osName === 'ios') { detectedBrowser = BROWSER_IOS; }
    if (osName === 'macos') { detectedBrowser = BROWSER_MACOS; }

    // Browser-dependent
    if (detectedBrowser === null && SUPPORTED_BROWSERS.includes(browserName)) { detectedBrowser = browserName; }

    if (detectedBrowser) {
        return {
            key: detectedBrowser,
            label: BROWSER_LABELS[detectedBrowser]
        }
    } else {
        // Default.
        return null;
    }
}

export function getBrowser(browserKey) {
    if (SUPPORTED_BROWSERS.indexOf(browserKey) === -1) {
        throw new Error(`${browserKey} is not a supported browser`);
    }
    return {key: browserKey, label: BROWSER_LABELS[browserKey]};
}