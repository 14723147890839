export function isExternal(url) {
    return new URL(document.baseURI).origin !== new URL(url, document.baseURI ).origin;
}

export function getAbsoluteUrl(url) {
    if (isExternal(url)) {
        return url;
    } else {
        return new URL(url, window.location.origin).href;
    }
}