import {Button, Container} from "react-bootstrap";
import {Col, Row} from "react-bootstrap";
import {getAbsoluteUrl} from "../utils/url";


export default function TermsAndConditionsPage(props) {
    const data = props.data;
    const translations = data.translations;
    const logoPath = getAbsoluteUrl(data.logoPath);

    return (
        <Row className="my-auto">
            <Col className="m-3 p-0 d-flex align-items-center">
                {logoPath && (
                    <img className="mx-3 logo-image" src={logoPath} alt="Company Logo"/>
                )}
            </Col>
            <Col className="m-3 mt-3 p-0 d-flex align-items-center">
                <Container className="partition-border">
                    <h2 className="fw-bold mb-3">{translations.termsAndConditions.welcomeText}</h2>
                    <p className="fs-5" dangerouslySetInnerHTML={{__html: translations.termsAndConditions.introductionText}}/>
                    <p className="fs-6" dangerouslySetInnerHTML={{__html: translations.termsAndConditions.eulaAcceptanceText}}/>

                    <Container fluid className="d-grid">
                        <Button
                            className="btn btn-sm mx-auto px-3 py-2 shadow-sm"
                            onClick={() => props.setHasAcceptedEula(true)}
                        >{translations.termsAndConditions.eulaAcceptButtonLabel}</Button>
                    </Container>
                </Container>
            </Col>
        </Row>
    )
}