import {useState} from "react";
import {Button} from "react-bootstrap";
import RoundSpinner from "./Spinner";
import {EnrolmentAPI} from "../api/EnrolmentAPI";


export default function DnsTestButton(props) {
    const [isLoading, setIsLoading] = useState(false);

    const testDnsConfiguration = () => {
        setIsLoading(true);
        EnrolmentAPI.testDns(props.dnsTestUrl).then(
            () => {
                props.successCallback();
            }
        ).catch(
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    props.failureCallback();
                } else {
                    props.successCallback();
                }
            }
        ).finally(
            () => {
                setIsLoading(false);
            }
        )
    }

    return (
        <>
            {isLoading && RoundSpinner()}

            {!isLoading && (
                <Button
                    className="btn btn-sm btn-primary mx-auto p-3 shadow-sm"
                    onClick={testDnsConfiguration}
                >
                    <strong>{props.label}</strong>
                </Button>
            )}
        </>
    )
}
