import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useEffect, useState} from "react";


export default function ClipboardCopy(props) {
    const copiedTooltipDuration = 3000;

    const [tooltip, setTooltip] = useState(props.copyTooltip);

    useEffect(() => {
        setTooltip(props.copyTooltip)
    }, [props.copyTooltip, props.copiedTooltip]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.value).then(
            () => {
                setTooltip(props.copiedTooltip);
                setTimeout(() => setTooltip(props.copyTooltip), copiedTooltipDuration);
            }
        )
    }

    const showTooltip = (props) => (
        <Tooltip {...props}>
            <span className="text-nowrap">{tooltip}</span>
        </Tooltip>
    )

    return (
        <OverlayTrigger
            placement="top"
            overlay={showTooltip}
        >
            <Badge bg="secondary" onClick={copyToClipboard}>{props.value}</Badge>
        </OverlayTrigger>
    )
}
