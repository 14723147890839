import {api} from "./core";
import {createApiCancelObject, getFileNameFromContentDisposition} from "./utils";


export const EnrolmentAPI = {
    getBlockedPageData: async function (companyId, cancel = false) {
        const response = await api.request({
            url: `/get-blocked-page-data/${companyId}`,
            method: "GET",
            signal: cancel ? apiCancelObject(this.getBlockedPageData.name).signal : undefined
        });
        return response.data;
    },
    getEnrolmentData: async function (enrolmentCode, cancel = false) {
        const response = await api.request({
            url: `/get-enrolment-data/${enrolmentCode}`,
            method: "GET",
            signal: cancel ? apiCancelObject(this.getEnrolmentData.name).signal : undefined
        });
        return response.data;
    },
    disableEnrolmentCode: async function (enrolmentCode, cancel = false) {
        await api.request({
            url: `/deactivate-code/${enrolmentCode}`,
            method: "POST",
            signal: cancel ? apiCancelObject(this.disableEnrolmentCode.name).signal : undefined
        })
    },
    getIOSProfileDownloadUrl: (enrolmentCode) => {
        return `${process.env.REACT_APP_API_ENDPOINT}/generate-apple-profile/${enrolmentCode}`;
    },
    downloadProfile: async function (enrolmentCode, cancel = false) {
        const response = await api.request({
            url: this.getIOSProfileDownloadUrl(enrolmentCode),
            method: "GET",
            headers: {
                Accept: "application/x-apple-aspen-config"
            },
            signal: cancel ? apiCancelObject(this.downloadProfile.name).signal : undefined
        });

        const filename = getFileNameFromContentDisposition(response?.headers["content-disposition"]);
        const blob = new Blob([response.data], { type: response?.headers['content-type'] });

        const reader = new FileReader();
        reader.onloadend = function () {
            const arrayBuffer = reader.result;
            const anchor = document.createElement("a");

            anchor.href = URL.createObjectURL(new Blob([arrayBuffer], { type: response?.headers['content-type'] }));
            anchor.download = filename;
            anchor.style.display = "none";

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        };
        reader.readAsArrayBuffer(blob);
    },
    testDns: async function (dnsTestUrl, cancel = false) {
        await api.request({
            url: dnsTestUrl,
            method: "GET",
            signal: cancel ? apiCancelObject(this.testDns.name).signal : undefined
        })
    }
}

const apiCancelObject = createApiCancelObject(EnrolmentAPI);