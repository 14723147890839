import {useState} from "react";
import {Container, InputGroup} from "react-bootstrap";
import {Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";


export default function IndexPage() {
    const navigate = useNavigate();

    const [isValid, setIsValid] = useState(false);
    const [enrolmentCode, setEnrolmentCode] = useState(undefined);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setIsValid(true);
        navigate(`/${enrolmentCode}`);
    }

    const onEnrolmentCodeChange = (event) => {
        setEnrolmentCode(event.target.value);
    }

    return (
        <Container fluid className={"mt-5 height-100vh"}>
            <div className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <p className="fs-3 fw-bold">Please provide an enrolment code to proceed:</p>
                    <Form noValidate validated={isValid}
                          onSubmit={handleSubmit.bind(this)}
                    >
                        <InputGroup>
                            <Form.Control
                                required
                                type="text"
                                placeholder="xxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxx"
                                onChange={onEnrolmentCodeChange}
                                defaultValue=""/>
                            <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
                            <Button type="submit">Enrol</Button>
                        </InputGroup>
                    </Form>
                </div>
            </div>
        </Container>
    )
}
