import {Card, Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {EnrolmentAPI} from "../api/EnrolmentAPI";
import {getParametrisedTranslationConfig} from "../utils/translation";
import {ERROR_COMPANY_NOT_FOUND, ERROR_GENERIC, ERROR_HEADER} from "../utils/errorsMessages";
import RoundSpinner from "../components/Spinner";
import LanguageSelector from "../components/LanguageSelector";
import ErrorMessage from "../components/ErrorMessage";
import MALWARE_SHIELD from '../assets/malware_shield.png'
import {getAbsoluteUrl} from "../utils/url";


export default function BlockedPage(props) {
    const params = useParams();

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState({
        companyName: null,
        productName: null,
        logoPath: null,
        translationConfig: null,
        translations: null,
    });


    const fetchData = () => {
        if (!isFetchingData) {
            setIsFetchingData(true);

            EnrolmentAPI.getBlockedPageData(params.companyId).then(
                (companyData) => {

                    getParametrisedTranslationConfig(companyData);

                    setData({
                        companyName: companyData.companyName,
                        productName: companyData.productName,
                        logoPath: getAbsoluteUrl(companyData.logoPath),
                        translationConfig: companyData.translationConfig,
                        translations: getTranslations(companyData.translationConfig.translations[companyData.translationConfig.defaultLanguage], companyData),
                    });
                }
            ).catch(
                (error) => {
                    if (error?.response?.status === 404) {
                        setErrorMessage(ERROR_COMPANY_NOT_FOUND);
                    } else {
                        console.error(error);
                        setErrorMessage(ERROR_GENERIC);
                    }
                }
            ).finally(
                () => {
                    setIsLoading(false);
                }
            );
        }
    }

    const getTranslations = (translations, contextData) => {
        return JSON.parse(JSON.stringify(translations));
    }

    const setLanguage = (language) => {
        const newTranslations = getTranslations(data.translationConfig.translations[language], data)
        setData({...data, translations: newTranslations});
    }

    fetchData();

    return (
        <Container fluid className={"height-100vh"}>

            {isLoading && (
                <Container className="mt-5">
                    <RoundSpinner/>
                </Container>
            )}

            {!isLoading && errorMessage && (
                <ErrorMessage
                    header={ERROR_HEADER}
                    message={errorMessage}
                />
            )}

            {!isLoading && !errorMessage && (
                <Container fluid>
                    {data.translationConfig['enabledLanguages'].length > 1 && (
                        <Container className="mx-auto enrolment-card my-1 d-flex justify-content-end">
                            <LanguageSelector
                                default={data.translationConfig['enabledLanguages'][0]}
                                languages={data.translationConfig['enabledLanguages']}
                                onChangeCallback={(value) => { setLanguage(value); }}
                            />
                        </Container>
                    )}
                    <Container className="mx-auto min-height-50vh">
                        <Card className="enrolment-card">
                            <Row className="my-auto">
                                <Col className="m-3 p-0 d-flex align-items-center">
                                    {data.logoPath && (
                                        <img className="mx-3 logo-image" src={data.logoPath} alt="Company Logo"/>
                                    )}
                                </Col>
                                <Col className="m-3 mt-3 p-0 d-flex align-items-center">
                                    <Container className="partition-border text-center">
                                        <img className="mx-auto" src={MALWARE_SHIELD} width="100px" alt="Padlock icon"/>
                                        <p className="fs-3 mt-3" dangerouslySetInnerHTML={{__html: data.translations.blocked.header}}/>
                                        <p className="lead" dangerouslySetInnerHTML={{__html: data.translations.blocked.message}}/>
                                    </Container>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Container>
            )}
        </Container>
    )
}