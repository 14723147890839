export function createApiCancelObject(apiObject) {
    const apiCancelObject = {}
    Object.getOwnPropertyNames(apiObject).forEach(
        (apiPropertyName) => {
            const cancellationControllerObject = {
                controller: undefined,
            }
            apiCancelObject[apiPropertyName] = {
                handleRequestCancellation: () => {
                    if (cancellationControllerObject.controller) {
                        cancellationControllerObject.controller.abort()
                    }
                    cancellationControllerObject.controller = new AbortController()
                    return cancellationControllerObject.controller
                }
            }
        }
    )
    return apiCancelObject
}

export function getFileNameFromContentDisposition(contentDispositionHeader = null) {
  if (
    !contentDispositionHeader ||
    typeof contentDispositionHeader !== "string"
  ) {
    return null;
  }

  const fileName =
    contentDispositionHeader
      ?.split(";")[1]
      ?.split("=")[1]
      ?.replace('"', "")
      ?.replace('"', "") ?? null;

  return fileName;
}
