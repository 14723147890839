import {Dropdown} from "react-bootstrap";
import {useState} from "react";

export default function LanguageSelector(props) {
    const [language, setLanguage] = useState(props.default);

    const changeLanguage = (language) => {
        setLanguage(language);
        props.onChangeCallback(language);
    }

    return (
        <Dropdown>
            <Dropdown.Toggle>
                {language}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {props.languages.map(
                    (lang, i) => {
                        return (
                            <Dropdown.Item key={`flag${i}`} onClick={() => changeLanguage(lang)}>{lang}</Dropdown.Item>
                        )
                    }
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}