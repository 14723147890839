import {Container} from "react-bootstrap";
import RED_CROSS from "../assets/red_x_cross.png"

export default function ErrorMessage(props) {
    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <Container className="text-center">
                <img className="mx-auto" src={RED_CROSS} width="100px" alt="Error icon"/>
                <p className="fs-3 mt-3" dangerouslySetInnerHTML={{__html: props.header}}/>
                <p className="lead" dangerouslySetInnerHTML={{__html: props.message}}/>
            </Container>
        </Container>
    )
}
