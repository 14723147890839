import {Component} from "react";
import './app.css'
import { Route, Routes } from "react-router-dom"

import BlockedPage from "./pages/BlockedPage";
import EnrolmentPage from "./pages/EnrolmentPage";
import IndexPage from "./pages/IndexPage";

export default class App extends Component {
    render() {
        return (
            <Routes>
                <Route exact path="/" element={<IndexPage/>}/>
                <Route path="/:enrolmentCode" element={<EnrolmentPage/>}/>
                <Route path="/blocked/:companyId" element={<BlockedPage/>}/>
            </Routes>
        )
    }
}
